<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <UserCard :data="dashboardData.totalBooked" />
      </b-col>
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <UserCard :data="dashboardData.totalReturned" />
      </b-col>
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <UserCard :data="dashboardData.totalCancelled" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import UserCard from '@/components/dashboard/Card.vue'
import { UserDashboardService } from '@/services'
import moment from 'moment'

export default {
  name: 'UserDashboard',

  middleware: ['auth', 'user'],
  components: {
    UserCard
  },
  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      date: '',
      dashboardData: {
        totalBooked: {
          iconClass: ' rounded-circle iq-card-icon dark-icon-light iq-bg-success',
          icon: 'ri-inbox-fill',
          result: 0,
          title: ''
        },
        totalReturned: {
          iconClass: ' rounded-circle iq-card-icon dark-icon-light iq-bg-warning',
          icon: 'ri-inbox-fill',
          result: 0,
          title: ''
        },
        totalCancelled: {
          iconClass: ' rounded-circle iq-card-icon dark-icon-light iq-bg-danger',
          icon: 'ri-inbox-fill',
          result: 0,
          title: ''
        }
      }
    }
  },

  mounted () {
    core.index()
    this.getData()
  },

  methods: {
    async getData () {
      await UserDashboardService.get().then(({ data }) => {
        this.dashboardData.totalBooked.result = data?.total_booked?.data || 0
        this.dashboardData.totalCancelled.result = data?.cancelled?.data || 0
        this.dashboardData.totalReturned.result = data?.returned?.data || 0

        const month = moment().format('MMMM')
        this.dashboardData.totalBooked.title = `Total booked for the month of  ${month}`
        this.dashboardData.totalReturned.title = `Total returned for the month of ${month}`
        this.dashboardData.totalCancelled.title = `Total cancelled for the month of ${month}`
      })
    }
  }
}
</script>
