<template>
  <iq-card>
    <template v-slot:body>
      <div class="d-flex d-flex align-items-center justify-content-between mb-1">
        <div>
          <h2>{{ data.result }}</h2>
          <p class="fontsize-sm m-0">
            {{ data.title }}
          </p>
        </div>
        <div :class="data.iconClass">
          <i :class="data.icon" />
        </div>
      </div>

    </template>
  </iq-card>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'UserCard',
  middleware: ['auth'],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },

  mounted () {
    core.index()
  }

}
</script>
